/**
 * Thom Cookie
 */

 // Set
export function thomCookieSet (name, value, days) {
    if (days) {
        var date = new Date()
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
        var expires = '; expires=' + date.toGMTString();
    } else {
        var expires = '';
    }
    document.cookie = name + '=' + value + expires + '; path=/'
}

// Get
export function thomCookieGet (name) {
    var nameEQ = name + '='
    var ca = document.cookie.split(';')
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i]
        while (c.charAt(0) == ' ') c = c.substring(1, c.length)
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length)
    }
    return null
}

// Delete 
export function thomCookieDel (name) {
    thomCookieSet(name, '', -1)
}