/* eslint-disable no-redeclare */
/* eslint-disable no-undef */
/* eslint-disable no-inner-declarations */
/* eslint-disable no-unused-vars */
/**
 * @title App
 * @description Application entry point
  */


// Jquery
import $ from "jquery";
window.jQuery = $;
window.$ = $;
// var Modernizr = require("browsernizr");

// Barba - pages transition
import barba from "@barba/core";
import barbaCss from "@barba/css";

import Flickity from "flickity";
import "flickity/css/flickity.css";
import utils from "fizzy-ui-utils/utils";
require("flickity-fade");
require("flickity-bg-lazyload");
require("flickity-as-nav-for");

// Gallery
import lightGallery from "lightgallery";
import "lg-video";

// Modal
import modal from "jquery-modal";

// Lazyload image
import LazyLoad from "vanilla-lazyload";

// Infinite scroll
var InfiniteScroll = require("infinite-scroll");

// Google maps api
import { Loader } from "@googlemaps/js-api-loader";

// Components
import spinner from "./components/spinner";
// import "./components/thom_dealers";

// Cookie
import {thomCookieSet, thomCookieGet, thomCookieDel} from "./components/thom_cookie";

require("./components/thom_advanced_search");

var jQBridget = require("jquery-bridget");
var Packery = require("packery");
$.bridget("packery", Packery, $);

// var imagesLoaded = require("imagesloaded");

require("jquery.resizeend");

// Thom Form
require("./components/thom_form");

// Thom Map
require("./components/thom_map");

require("./components/thom_mapping")

// Modules
import navbar from "../modules/navbar/navbar";
import searchOverlay from "../modules/navbar/search-overlay";
require("../modules/dropdown/dropdown");
import thomDropdown from "../modules/dropdown/dropdown";
import {toa, toaInit, toaUpdate, toaDisable, toaParallaxMove} from "../modules/thomObserveAnimation/toa";

// paginationom "twbs-pagination"
// require("twbs-pagination");

import "@chenfengyuan/datepicker/dist/datepicker.min.js";
import "@chenfengyuan/datepicker/i18n/datepicker.it-IT";
import "@chenfengyuan/datepicker/i18n/datepicker.de-DE";
import "@chenfengyuan/datepicker/i18n/datepicker.fr-FR";
import "@chenfengyuan/datepicker/i18n/datepicker.pl-PL";
import "@chenfengyuan/datepicker/i18n/datepicker.es-ES";
import "@chenfengyuan/datepicker/i18n/datepicker.pt-BR";
import "@chenfengyuan/datepicker/i18n/datepicker.ru-RU";
import "@chenfengyuan/datepicker/i18n/datepicker.zh-CN";
import "@chenfengyuan/datepicker/i18n/datepicker.ar-IQ";
import "@chenfengyuan/datepicker/dist/datepicker.min.css";

import {gsap} from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

import OpenSeadragon from "openseadragon";

var bodyScrollLock = require('body-scroll-lock');
var disableBodyScroll = bodyScrollLock.disableBodyScroll;
var enableBodyScroll = bodyScrollLock.enableBodyScroll;

// exist element
$.fn.exists = function () {
  return this.length !== 0;
};

// Modal default options
$.modal.defaults = {
  clickClose: true,
  escapeClose: true,
  fadeDuration: 250,
  fadeDelay: 1.1,
  showClose: false,
  spinnerHtml: '<div id="spinner" class="default"><div class="sk-chase"><div class="sk-chase-dot"></div><div class="sk-chase-dot"></div><div class="sk-chase-dot"></div><div class="sk-chase-dot"></div><div class="sk-chase-dot"></div><div class="sk-chase-dot"></div></div></div>',
  blockerClass: "jquery-modal"
}

// Form Response Action
function MyThomFormResponseAction(title, text) {
  var mytitle;
  if (title !== undefined && title !== null && title !== "") {
    mytitle = "<div class='h2'>" + title + "</div>";
  } else {
    mytitle = "";
  }

  var myHtml =
    '<div class="modal modal-callback">' +
      '<a href="#close" rel="modal:close" class="close"><img src="/img/ico-close.svg"></a>' +
      mytitle +
      "<div class='paragraph'>" + text + "</div>"; +
    '</div>';

  $(myHtml).appendTo("body").modal();
}

// Colors modal
function ColorsModal(link) {
  var modaltitle = $(link).find(".title").text();
  var modalimage = $(link).data("image");

  var myHtml =
    '<div class="modal modal-colors">' +
      '<div class="modal-colors-title">' +
        '<div class="title-font">' +
        modaltitle +
        '</div>' +
        '<a href="#close" rel="modal:close" class="close"><img src="/img/ico-close.svg"></a>' +
      '</div>' +
      '<div class="modal-colors-content">' +
        '<img data-src="' +
        modalimage +
        '" alt="" class="lazy"></img>' +
      '</div>' +
    '</div>';

  $(myHtml).appendTo("body").modal();

  $(document).on($.modal.OPEN, function (event, modal) {
    myLazyLoad.update();
  });

  $(document).on($.modal.AFTER_CLOSE, function (event, modal) {
    $(".modal-colors").remove();
  });
}

// Carousel instances
let flktyHome;
let flktyCollectionsPreviewBk;
let flktyProjectsPreviewBk;
let flktyProjectsSinglepage;

// Carousel Parallax effect
function CarouselParallax(carousel, instance) {
  let cell = $(carousel).find(".carousel-cell").length;
  let imgs = $(carousel).find(".carousel-cell-image");
  // get transform property
  let docStyle = document.documentElement.style;
  let transformProp =
    typeof docStyle.transform === "string" ? "transform" : "WebkitTransform";

  instance.on("scroll", function () {
    instance.slides.forEach(function (slide, j) {
      let img = imgs[j];
      let x = 0;
      if (0 === j && cell > 2) {
        x =
          Math.abs(instance.x) > instance.slidesWidth
            ? instance.slidesWidth +
              instance.x +
              instance.slides[instance.slides.length - 1].outerWidth +
              slide.target
            : slide.target + instance.x;
      } else if (j === instance.slides.length - 1) {
        x =
          Math.abs(instance.x) + instance.slides[j].outerWidth <
          instance.slidesWidth
            ? slide.target -
              instance.slidesWidth +
              instance.x -
              instance.slides[j].outerWidth
            : slide.target + instance.x;
      } else {
        x = slide.target + instance.x;
      }
      img.style[transformProp] = "translateX(" + x * (-1 / 3) + "px)";
    });
  });
}

// Carousel Progress bar
function CarouselProgressbar(carousel, instance) {
  let cell = $(carousel).find(".carousel-cell").length;
  if (cell > 1) {
    $(carousel).find(".carousel-progress").show();
    let caption = $(carousel).find(".carousel-cell-caption");
    let duration = 5000;
    let interval = 10;
    let progressBar = $(carousel).find(".progress");
    let isPaused = false;

    caption.on("mouseenter", function () {
      isPaused = true;
    });

    caption.on("mouseleave", function () {
      isPaused = false;
    });

    // Main function
    let percentTime;
    let step;
    let tick;

    function startProgressbar() {
      resetProgressbar();
      percentTime = 0;
      isPaused = false;
      tick = window.setInterval(increase, interval);
    }

    function increase() {
      if (!isPaused) {
        step = duration / interval;
        percentTime += 100 / step;
        progressBar.width(percentTime + "%");
        if (percentTime >= 100) {
          if (instance.selectedIndex === instance.slides.length - 1) {
            instance.select(0);
          } else {
            instance.next();
          }
          startProgressbar();
        }
      }
    }

    function resetProgressbar() {
      progressBar.width(0 + "%");
      clearTimeout(tick);
    }

    startProgressbar();

    instance.on("change", function (index) {
      startProgressbar();
    });
  } else {
    $(carousel).find(".carousel-progress").hide();
  }
}

// share buttons
function shareButtons() {
  // Section Intro Fade Up
  if ($(".share").exists()) {
    
    var g = 0;

    function shareButtonsInit(){

      if ($("html").hasClass("touchevents")) {
        $(".share-btn.messenger-desk").hide();
        $(".share-btn.messenger-mob").show(0, function () {
          $(this).css("display", "block");
        });
      } else {
        $(".share-btn.messenger-mob").hide();
        $(".share-btn.messenger-desk").show(0, function () {
          $(this).css("display", "block");
        });
      }

      
      if (g !== 1) {
        $(".copy-link").click(function (e) {
          e.preventDefault();
          var link = $(this).attr("href");
          var $temp = $("<input>");
          $("body").append($temp);
          $temp.val(link).select();
          // $temp.setSelectionRange(0, 99999);
          document.execCommand("copy");
          $temp.remove();

          $($(this).data("modal")).modal();
          return false;
        });
      }

      if (window.innerWidth <= 990) {
        $(".btn.share").click(function () {
          $(this).toggleClass("is-active");
        });
      }
    }

    $(window).on("resizeend", 250, function () {
      g = 1;
      shareButtonsInit();
    });

    shareButtonsInit();
  } 
}

// TOM map Marker Icon
function myThomMapMarkerIcon(item, isDett) {
  var category = "default";

  if ($(item).is("[cat]")) {
    category = $(item).attr("cat");
  }

  var markerIcon = {
    contact: {
      icon: "" + _root + "ico-marker.svg",
      height: 23,
      width: 18,
    },
    dealers: {
      icon: "" + _root + "img/marker.png",
      height: 24,
      width: 18,
    },
  };

  return markerIcon.dealers;
}

function jobStepsForm(stepForm){
  let jobForm = '';
  jobForm = $(".formSteps-content .thom-form");
  jobForm.thomForm({
    errorMsg: jobForm.find(".thom-form__error"),
    errorCallback: function(myTitle, myMessage) {
      if (myTitle) {
        myTitle = "<div class='paragraph'><strong>" + myTitle + "</strong></div>";
      }
      if (myMessage) {
        myMessage = "<div class='paragraph'>" + myMessage + "</div>";
      }

      if (myTitle || myMessage) {
        jobForm.find(".thom-form__error").html(myTitle + myMessage);
        
        jobForm.find(".thom-form__error").fadeIn(300);

        $('html,body').animate({
          scrollTop: jobForm.offset().top - $('.navbar').height()
        }, 600);
      }  
    },
    myCallback: function(myTitle, myMessage) {
      MyThomFormResponseAction(myTitle, myMessage);
    },
    responseContent: $(".thom-form-response"),
    steps: true,
    stepsCallback: function() {
      jobStepsForm(1);
    }
  });

  jobForm.find('.duplicate').each(function(){
    var elemToDuplicate = $(this);
    elemToDuplicate.find('.btn').click(function(){
      var elemLast = elemToDuplicate.find('.duplicate-item:last');
      
      $.ajax({
        url: $(this).data('action'),
      })
        .done(function( data ) {
          $(data).insertAfter(elemLast);
        });
    })
  });

  if ($(".thom-form__recaptcha").length != 0 && stepForm != 0) {
    recaptchaLoad();
  }
}

function dealersSearch(){
  var MyDealersMap;

    const GoogleMapsDealersLoader = new Loader({
      apiKey: _gmapkey,
      version: "weekly",
      libraries: ["places", "geometry"],
    });

    var autocomplete = 0;
    var postdata;

    GoogleMapsDealersLoader.load()
      .then(() => {
        // Thom Map INIT
        MyDealersMap = $("#map").thomMap({
          autocomplete: autocomplete,
          content: $(".stores"),
          fitbounds: true,
          getMarkerIcon: function (item) {
            return myThomMapMarkerIcon(item, true);
          },
          params: params,
          searchFromSelect: true,
          // searchBtn: $(".stores-search-btn"),
          searchCallback: function(){
            $('.stores-search-preview').hide();
            $('.stores-results').show();
          },
          url: thomMap_url,
          zoomControl: true,
          zoomControlPosition: google.maps.ControlPosition.RIGHT_TOP,
          zoomToMarker: true,
        });
      })
      .catch((e) => {
        // do something
      });
      
    var selects;
    var selectChange = function() {
      selects = $("#dealersSearch .thom-form__select");

      selects.change(function(){
        var selectName = $(this).attr('id');
        getContents(selectName);
      });
    }

    selectChange();
    
    var getContents = function(selectId) {
      MyDealersMap = null;
      postdata = {changed: selectId};

      // console.log(postdata)
      
      selects.each(function(){
        var selectName = $(this).attr('id');
        var selectVal = $(this).val();

        postdata[''+ selectName +''] = selectVal;
      });

      // console.log(postdata)

      $('#dealersSearch').addClass('loading');

      var elem = document.createElement('div');
      elem.setAttribute('id', 'spinnerform');
      elem.innerHTML =
        '<div class="sk-chase">' +
          '<div class="sk-chase-dot"></div>' +
          '<div class="sk-chase-dot"></div>' +
          '<div class="sk-chase-dot"></div>' +
          '<div class="sk-chase-dot"></div>' +
          '<div class="sk-chase-dot"></div>' +
          '<div class="sk-chase-dot"></div>' +
        '</div>';

        $('#dealersSearch').append(elem);
        $('#spinnerform').fadeIn();

      $.ajax({
        url: thomDealersGetFilters_url,
        data: postdata,
        dataType: 'html'
      })
        .done(function( data ) {
          $('.dealersSearch-content').html(data);
          $('#dealersSearch').find('#spinnerform').fadeOut(300, function(){
            $(this).remove();
            $('#dealersSearch').removeClass('loading');
          });

          searchClick(postdata);
          selectChange();
        });
    }

    var searchClick = function(postdata) {
      $('.stores-search-btn').click(function(){
        $('#map').thomMap('searchPoints', postdata);
      });
    }
}

var myLazyLoad;
var ThomAdvancedSearch = null;

// Start APP
function myapp() {
  // reset navigation set
  $("body")
    .removeClass("navbar--search-opened")
    .removeClass("navbar--opened");

    $(".navbar")
    .removeClass("out-stage")

    $("html,body").animate(
      {
        scrollTop: 0,
      },
      400
    );

  $(".wrapper").removeAttr("style");
  $(".main").removeAttr("style");
  // window.scrollBy(0, 0);
  let packeryInstance = null;
  myLazyLoad = null;
  
  myLazyLoad = new LazyLoad({
    unobserve_entered: true,
    elements_selector: ".lazy",
    // callback_loaded: function(el){
    //   if($(el).parent().hasClass('grid-item')){
    //     packeryInstance.packery('layout');
    //   }
    // }
  });

  // Thom Observer Animation
  if ($(".js-toa").exists()) {
    toa();
  }

  // search
  searchOverlay();

  // Dropdown
  thomDropdown();

  // Share
  shareButtons();

  // Home Carousel
  if ($(".carousel-home").exists()) {
    var firstLL = 0;
    flktyHome = new Flickity(".carousel-home", {
      autoPlay: 0,
      cellSelector: ".carousel-home .carousel-cell",
      pauseAutoPlayOnHover: true,
      prevNextButtons: false,
      pageDots: true,
      bgLazyLoad: true,
      lazyLoad: 2,
      draggable: true,
      on: {
        lazyLoad: function (event, cellElement) {
          if($(cellElement).index() === 0 && firstLL === 0){
            firstLL = 1;
            $('.emotional-home-content').addClass('tween');
          }
        },
      },
    });

    CarouselParallax(".carousel-home", flktyHome);
    CarouselProgressbar(".carousel-home", flktyHome);
  }

  // collections preview carousel
  if ($(".collections-preview").exists()) {
    $(".collections-preview--carousel").removeClass("is-hidden");
    let flktyCollectionsPreview = new Flickity(
      ".collections-preview--carousel",
      {
        cellSelector: ".collections-preview--carousel .carousel-cell",
        // asNavFor: '.collections-preview--carousel-bk',
        pageDots: false,
        contain: true,
        lazyLoad: 2,
        prevNextButtons: false,
        fade: true,
        on: {
          ready: function () {
            $(".collections-preview .overall .slide-n .tot").html(
              $(".collections-preview--carousel .carousel-cell").length
            );
          },
        },
      }
    );

    $(".collections-preview--carousel-bk").removeClass("is-hidden");
    flktyCollectionsPreviewBk = new Flickity(
      ".collections-preview--carousel-bk",
      {
        cellSelector: ".collections-preview--carousel-bk .carousel-cell",
        asNavFor: ".collections-preview--carousel",
        draggable: false,
        pageDots: false,
        lazyLoad: 2,
        prevNextButtons: false
      }
    );
    CarouselParallax(
      ".collections-preview--carousel-bk",
      flktyCollectionsPreviewBk
    );

    // previous
    $(".collections-preview .overall .nav-btn-prev").on("click", function () {
      flktyCollectionsPreview.previous();
    });
    // next
    $(".collections-preview .overall .nav-btn-next").on("click", function () {
      flktyCollectionsPreview.next();
    });
    // on change
    flktyCollectionsPreview.on("change", function (index) {
      $(".collections-preview .overall .slide-n .current").html(index + 1);
    });
    // on select
    flktyCollectionsPreview.on("select", function () {
      $(".collections-preview .overall .overall-text-change").addClass("hide");
      setTimeout(function () {
        $(".collections-preview .overall .title").text(
          flktyCollectionsPreview.selectedElements[0].attributes.title
            .textContent
        );
        $(".collections-preview .overall .subtitle").text(
          flktyCollectionsPreview.selectedElements[0].attributes.subtitle
            .textContent
        );
        $(".collections-preview .overall .btn").attr(
          "href",
          flktyCollectionsPreview.selectedElements[0].attributes.link
            .textContent
        );
        $(".collections-preview .overall .overall-text-change").removeClass(
          "hide"
        );
      }, 350);
    });
  }

  // projects preview carousel
  if ($(".projects-preview").exists()) {

    $(".projects-preview--carousel-bk").removeClass("is-hidden");
    flktyProjectsPreviewBk = new Flickity(".projects-preview--carousel-bk", {
      cellSelector: ".projects-preview--carousel-bk .carousel-cell",
      // asNavFor: '.projects-preview--carousel',
      draggable: false,
      pageDots: false,
      lazyLoad: 2,
      prevNextButtons: false,
      on: {
        ready: function () {
          $(".projects-preview .overall .slide-n .tot").html(
            $(".projects-preview--carousel-bk .carousel-cell").length
          );
        },
      },
      // fade: true
    });
    CarouselParallax(".projects-preview--carousel-bk", flktyProjectsPreviewBk);

    // previous
    $(".projects-preview .overall .nav-btn-prev").on("click", function () {
      flktyProjectsPreviewBk.previous();
    });
    // next
    $(".projects-preview .overall .nav-btn-next").on("click", function () {
      flktyProjectsPreviewBk.next();
    });
    // on change
    flktyProjectsPreviewBk.on("change", function (index) {
      $(".projects-preview .overall .slide-n .current").html(index + 1);
    });
    // on select
    flktyProjectsPreviewBk.on("select", function () {
      $(".projects-preview .overall .overall-text-change").addClass("hide");
      setTimeout(function () {
        $(".projects-preview .overall .title").text(
          flktyProjectsPreviewBk.selectedElements[0].attributes.title
            .textContent
        );
        $(".projects-preview .overall .subtitle").text(
          flktyProjectsPreviewBk.selectedElements[0].attributes.subtitle
            .textContent
        );
        $(".projects-preview .overall .btn").attr(
          "href",
          flktyProjectsPreviewBk.selectedElements[0].attributes.link.textContent
        );
        $(".projects-preview .overall .overall-text-change").removeClass(
          "hide"
        );
      }, 350);
    });
  }

  // Lapite Features Carousel
  if ($(".lapitec-features-carousel").exists()) {
    $(".lapitec-features-carousel").removeClass("is-hidden");

    var flktyFeatures;

    function ToggleFeaturesFlickity() {
      if (matchMedia("screen and (min-width: 768px)").matches) {
        if ($(".lapitec-features-carousel").hasClass("flickity-enabled")) {
          flktyFeatures.destroy();
        }
      } else {
        flktyFeatures = new Flickity(".lapitec-features-carousel", {
          autoPlay: 0,
          cellSelector: ".lapitec-features-carousel-cell",
          pauseAutoPlayOnHover: true,
          prevNextButtons: false,
          pageDots: false,
          draggable: true,
        });

        // previous
        $(".lapitec-features-carousel-btn-prev").on("click", function () {
          flktyFeatures.previous();
        });
        // next
        $(".lapitec-features-carousel-btn-next").on("click", function () {
          flktyFeatures.next();
        });
      }
    }

    $(window).on("resizeend", 250, function () {
      ToggleFeaturesFlickity();
    });

    ToggleFeaturesFlickity();
  }

  // Lapite Awards Carousel
  if ($(".lapitec-awards-carousel").exists()) {
    $(".lapitec-awards-carousel").each(function(){
      var flktyAward;
      var carousel = $(this)[0];
      flktyAward = new Flickity(carousel, {
        cellSelector: ".carousel-cell",
        prevNextButtons: false,
        groupCells: true,
        cellAlign: 'left'
      });
    })
    
  }

  // Lapite Clients Carousel
  if ($(".lapitec-clients-carousel").exists()) {
    var flktyClients;
    flktyClients = new Flickity(".lapitec-clients-carousel", {
      cellSelector: ".carousel-cell",
      prevNextButtons: false,
      groupCells: true,
      cellAlign: 'left'
    });
  }

  // Lapite Certification Carousel
  if ($(".lapitec-certifications-carousel").exists()) {
    var flktyCertification;
    flktyCertification = new Flickity(".lapitec-certifications-carousel", {
      cellSelector: ".carousel-cell",
      prevNextButtons: false,
      groupCells: true,
      cellAlign: 'left'
    });
  }

  // Lapite Finishes Carousel
  if ($(".finishes-carousel").exists()) {
    var flktyFinishesGroupCells = false;

    var flktyFinishes;
    flktyFinishes = new Flickity(".finishes-carousel", {
      cellSelector: ".carousel-cell",
      prevNextButtons: false,
      groupCells: true,
      lazyLoad: 1,
    });
  }

  // Lapite Natural minerals Carousel
  if ($(".lapitec-minerals--carousel").exists()) {
    var flktyMinerals = new Flickity(".lapitec-minerals--carousel", {
      autoPlay: 5000,
      pauseAutoPlayOnHover: true,
      cellSelector: ".carousel-cell",
      draggable: true,
      fade: true,
      lazyLoad: true,
      pageDots: false,
      prevNextButtons: false,
      wrapAround: true
    });

    // previous
    $(".lapitec-minerals--carousel .nav-btn-prev").on("click", function () {
      flktyMinerals.previous();
    });
    // next
    $(".lapitec-minerals--carousel .nav-btn-next").on("click", function () {
      flktyMinerals.next();
    });
  }

  // Singlepage
  // Projects carousel 
  if ($(".projects-carousel-singlepage .carousel").exists()) {
    var projectCarousel = document.querySelector('.projects-carousel-singlepage');
    var carousel = projectCarousel.querySelector('.carousel');
    var cellsButtonGroup = document.querySelector('.projects-carousel-singlepage--page-dots');
    var cellsButtons = utils.makeArray( cellsButtonGroup.children );
    
    flktyProjectsSinglepage = new Flickity(carousel, {
      autoPlay: 0,
      cellSelector: ".projects-carousel-singlepage .carousel-cell",
      pauseAutoPlayOnHover: true,
      prevNextButtons: false,
      pageDots: false,
      bgLazyLoad: true,
      lazyLoad: 2,
      draggable: true
    });

    // update buttons on select
    flktyProjectsSinglepage.on( 'select', function() {
      var previousSelectedButton = cellsButtonGroup.querySelector('.is-selected');
      var selectedButton = cellsButtonGroup.children[ flktyProjectsSinglepage.selectedIndex ];
      previousSelectedButton.classList.remove('is-selected');
      selectedButton.classList.add('is-selected');
    });

    // cell select
    cellsButtonGroup.addEventListener( 'click', function( event ) {
      // if ( !matchesSelector( event.target, '.button' ) ) {
      //   return;
      // }
      var index = cellsButtons.indexOf( event.target );
      flktyProjectsSinglepage.select( index );
    });

    CarouselParallax(carousel, flktyProjectsSinglepage);
  }

  // add this code
  Flickity.prototype._createResizeClass = function () {
    this.element.classList.add("flickity-resize");
  };
  Flickity.createMethods.push("_createResizeClass");

  var FlickityResize = Flickity.prototype.resize;
  if ($(".flickity-resize").exists()) {
    Flickity.prototype.resize = function () {
      var $this = this;
      $(".flickity-resize").each(function () {
        $this.element.classList.remove("flickity-resize");
        FlickityResize.call($this);
        $this.element.classList.add("flickity-resize");
      });
    };
  }

  // Accordion
  if ($(".accordion-list").exists()) {
    $(".accordion-list").each(function(){
      var $list = $(this);
      var elms = $list.find(".accordion-item");
      $(elms).each(function(){
        if($(this).hasClass('active')){
          $(this).find(".accordion-content").slideToggle();
        }
      });
      

      elms.find('.accordion-title').click(function(){
        var $this = $(this).parent();
        if($list.hasClass('close-all')){
          elms.find(".accordion-content").stop().slideUp();
          elms.removeClass('active');          
          $this.find(".accordion-content").stop().slideDown();
          $this.toggleClass("active");
        } else {
          $this.find(".accordion-content").stop().slideToggle();
          $this.toggleClass("active");
        }
      })

    });
  }

  // Colors modal
  if ($(".colors-list-item[data-image]").exists()) {
    $(".colors-list-item[data-image]").click(function (e) {
      e.preventDefault();
      ColorsModal(this);
    });
  }

  // Dealers
  if ($("#dealersSearch").exists()) {
    dealersSearch();
  }

  // Stores
  if ($("#storesSearch").exists()) {
    var MyStoresMap;

    const GoogleMapsDealersLoader = new Loader({
      apiKey: _gmapkey,
      version: "weekly",
      libraries: ["places", "geometry"],
    });

    var autocomplete = 0;
    if ($("#storesSearch").is('[data-autocomplete]')) {
      autocomplete = 1;
    }

    GoogleMapsDealersLoader.load()
      .then(() => {
        // Thom Map INIT
        MyStoresMap = $("#map").thomMap({
          autocomplete: autocomplete,
          content: $(".stores"),
          fitbounds: true,
          getMarkerIcon: function (item) {
            return myThomMapMarkerIcon(item, true);
          },
          params: params,
          // searchBtn: $(".stores-search-btn"),
          searchCallback: function(){
            $('.stores-search-preview').hide();
            $('.stores-results').show();
          },
          url: thomMap_url,
          zoomControl: true,
          zoomControlPosition: google.maps.ControlPosition.RIGHT_TOP,
          zoomToMarker: true,
        });

        $('.stores-search-btn').click(function(){
          $('#map').thomMap('searchPoints');
        });
        
      })
      .catch((e) => {
        // do something
      });

    // $('.map-search-form .thom-dropdown--options-item').click(function(e){
    //   $('.map-search-radius .thom-dropdown--options-item').removeClass('is-selected');
    //   $(this).addClass('is-selected');
    // });
  }

  // Contacts Form
  if ($("#contactForm").exists()) {
    var contactForm = $("#contactForm");
    contactForm.thomForm({
      errorMsg: contactForm.find(".thom-form__error"),
      errorCallback: function(myTitle, myMessage) {
        if (myTitle) {
          myTitle = "<div class='paragraph'><strong>" + myTitle + "</strong></div>";
        }
        if (myMessage) {
          myMessage = "<div class='paragraph'>" + myMessage + "</div>";
        }

        if (myTitle || myMessage) {
          contactForm.find(".thom-form__error").html(myTitle + myMessage);
        }

        contactForm.find(".thom-form__error").fadeIn(300);

        $('html,body').animate({
          scrollTop: contactForm.offset().top - $('.navbar').height()
        }, 600);
      },
      myCallback: function(myTitle, myMessage) {
        MyThomFormResponseAction(myTitle, myMessage);
      }
    });
  }

  // guaranteeForm
  if ($("#guaranteeForm").exists()) {
    var guaranteeForm = $("#guaranteeForm");
    guaranteeForm.thomForm({
      errorMsg: guaranteeForm.find(".thom-form__error"),
      errorCallback: function(myTitle, myMessage) {
        if (myTitle) {
          myTitle = "<div class='paragraph'><strong>" + myTitle + "</strong></div>";
        }
        if (myMessage) {
          myMessage = "<div class='paragraph'>" + myMessage + "</div>";
        }

        if (myTitle || myMessage) {
          guaranteeForm.find(".thom-form__error").html(myTitle + myMessage);
        }

        guaranteeForm.find(".thom-form__error").fadeIn(300);

        $('html,body').animate({
          scrollTop: guaranteeForm.offset().top - $('.navbar').height()
        }, 600);
      },
      myCallback: function(myTitle, myMessage) {
        MyThomFormResponseAction(myTitle, myMessage);
      }
    });
  }

  // Contacts Map
  if ($(".contact-map").exists() && $(".contact-map #map").exists()) {
    var map;
    const GoogleMapsLoader = new Loader({
      apiKey: _gmapkey,
      version: "weekly",
      libraries: ["places"],
    });

    GoogleMapsLoader.load()
      .then(() => {
        map = new google.maps.Map(document.getElementById("map"), {
          center: { lat: params.latitude, lng: params.longitude },
          zoom: 8,
          maxZoom: 18,
          streetViewControl: false,
          mapTypeControl: false,
          scrollwheel: false,
          zoomControlOptions: {
            position: google.maps.ControlPosition.RIGHT_TOP,
          },
        });

        var image = {
          url: "" + _root + "img/marker.png",
          size: new google.maps.Size(77, 60),
        };
        var myMarker = new google.maps.Marker({
          position: { lat: params.latitude, lng: params.longitude },
          map: map,
          icon: image,
        });
        var bounds = new google.maps.LatLngBounds();
        bounds.extend(myMarker.getPosition());
        map.fitBounds(bounds);
      })
      .catch((e) => {
        // do something
      });
  }

  ThomAdvancedSearch = null
  // News
  if ($(".thom-search--filter-block").exists() && $('.news-list').exists() &&
  (typeof ThomAdvancedSearch === 'undefined' || ThomAdvancedSearch === null)) {

    var nextURL;

    function updateNextURL( doc ) {
      nextURL = $( doc ).find('.pagination__next').attr('href');
    }
    // get initial nextURL
    updateNextURL( document );

    var $infiniteContainer;

    function updateInfiniteScroll(){
      $infiniteContainer = null;
      $infiniteContainer = $('.thom-search--results .news-list .infinite-content').infiniteScroll({
        // options
        path: function() {
          return nextURL;
        },
        append: '.news-list-item',
        history: false,
        status: '.page-load-status',
        // debug: true
      });
  
      // update nextURL on page load
      $infiniteContainer.on( 'load.infiniteScroll', function( event, response ) {
        updateNextURL( response );
      });
  
      $infiniteContainer.on( 'append.infiniteScroll', function( event, response, path, items ) {
        items.forEach(item => {
          if ($(item).find(".lazy").length > 0) {
            myLazyLoad.update();
          }
          // Thom Observer Animation
          if ($(item).hasClass("js-toa")) {
            toaInit($(item));
          }
        });
      });
    }

    if ( $('.pagination__next').length ) {
      updateInfiniteScroll();
    }
    
    ThomAdvancedSearch = $('.thom-search').thomsearch({
      filterClass: '.thom-search--filter-btn',
      filtersContent: '.thom-search--filter-block',
      itemsPerPage: THOM_searchItemsPerPage,
      resultsCallback: function (data) {


        var infScroll = $('.thom-search--results .news-list .infinite-content').data('infiniteScroll');
        if(infScroll) {
          infScroll.destroy();
        }
        $('.thom-search--results').html(data);  
        ThomAdvancedSearch.thomsearch('loadingHide');

        var items = $('.thom-search--results .news-list-item');
        items.each(function(){
          if ($(this).find(".lazy").length > 0) {
            myLazyLoad.update();
          }
          // Thom Observer Animation
          if ($(this).hasClass("js-toa")) {
            toaInit($(this));
          }
        });

        updateNextURL( document );
        if ( $('.pagination__next').length ) {
          updateInfiniteScroll();
        }
      },
      urlContent: THOM_searchUrlContent
    });
  }

  // Projects
  // Filters
  if ($(".thom-search--filter-block").exists() && $('.projects-list').exists() &&
  (typeof ThomAdvancedSearch === 'undefined' || ThomAdvancedSearch === null)) {

    var nextURL;

    function updateNextURL( doc ) {
      nextURL = $( doc ).find('.pagination__next').attr('href');
    }
    // get initial nextURL
    updateNextURL( document );

    var $infiniteContainer;

    function updateInfiniteScroll(){
      $infiniteContainer = null;
      $infiniteContainer = $('.thom-search--results .projects-list .infinite-content').infiniteScroll({
        // options
        path: function() {
          return nextURL;
        },
        append: '.projects-list-item',
        history: false,
        status: '.page-load-status',
        // debug: true
      });
  
      // update nextURL on page load
      $infiniteContainer.on( 'load.infiniteScroll', function( event, response ) {
        updateNextURL( response );
      });
  
      $infiniteContainer.on( 'append.infiniteScroll', function( event, response, path, items ) {
        items.forEach(item => {
          if ($(item).find(".lazy").length > 0) {
            myLazyLoad.update();
          }
          // Thom Observer Animation
          if ($(item).hasClass("js-toa")) {
            toaInit($(item));
          }
        });
      });
    }

    if ( $('.pagination__next').length ) {
      updateInfiniteScroll();
    }
    
    ThomAdvancedSearch = $('.thom-search').thomsearch({
      filterClass: '.thom-search--filter-btn',
      filtersContent: '.thom-search--filter-block',
      infScroll: $infiniteContainer,
      itemsPerPage: THOM_searchItemsPerPage,
      orderByPosition: THOM_orderByPosition,
      resultsCallback: function (data) {

        var infScroll = $('.thom-search--results .projects-list .infinite-content').data('infiniteScroll');
        if(infScroll) {
          infScroll.destroy();
        }

        $('.thom-search--results').html(data);
        ThomAdvancedSearch.thomsearch('loadingHide');

        var items = $('.thom-search--results .projects-list-item');
        items.each(function(){
          if ($(this).find(".lazy").length > 0) {
            myLazyLoad.update();
          }
          // Thom Observer Animation
          if ($(this).hasClass("js-toa")) {
            toaInit($(this));
          }
        });

        updateNextURL( document );
        if ( $('.pagination__next').length ) {
          updateInfiniteScroll();
        }

        // Reset All filters
        if($('.filters-list .thom-search--filter-btn.is-selected').length > 0){
          $('.filters-list .thom-search--filter-reset-all').addClass('is-selected');
        }else{
          $('.filters-list .thom-search--filter-reset-all').removeClass('is-selected');
        }
      },
      url: THOM_searchUrlFilters,
      urlContent: THOM_searchUrlContent
    });

    // Show Reset All filters btn on init
    if($('.filters-list .thom-search--filter-btn.is-selected').length > 0){
      $('.filters-list .thom-search--filter-reset-all').addClass('is-selected');
    }

    // Reset All filters btn Click
    $('.filters-list .thom-search--filter-reset-all').click(function(){
      ThomAdvancedSearch.thomsearch('reset');
      $('.filters-list .thom-search--filter-reset-all').removeClass('is-selected');
    });
  }

  // Jobs
  // Form with steps
  // Contacts Form
  if ($(".formSteps-content .thom-form").exists()) {
    jobStepsForm(0);
  }

  // Lapite Images
  // Projects carousel 
  if ($(".images--carousel .carousel").exists()) {
    var imagesCarousel = document.querySelector('.images--carousel');
    var carousel_imgs = imagesCarousel.querySelector('.carousel');
    
    var flktyImages = new Flickity(carousel_imgs, {
      autoPlay: 0,
      cellSelector: ".carousel-cell",
      pauseAutoPlayOnHover: true,
      prevNextButtons: false,
      pageDots: true,
      lazyLoad: 1,
      draggable: true
    });    

    CarouselParallax(carousel_imgs, flktyImages);
  }

  // Configurator
  if ($('.product-configurator').exists()) {
    var productScrollElement = document.querySelector('.product-configurator');
    $('.product-configurator .scrolling-propagation').on('click', function(){
      $('html').css('overflow-y', 'unset');
      $('.product-configurator').addClass('active');
      disableBodyScroll(productScrollElement);
    });
    $('.product-configurator').on('mouseleave', function(){
      $('html').css('overflow-y', 'scroll');
      $('.product-configurator').removeClass('active');
      enableBodyScroll(productScrollElement);
    })
  }

  // Color Dett Finishes Carousel
  if ($(".color-dett--finishes--carousel").exists()) {

    var flktyColorFinishes;
    flktyColorFinishes = new Flickity(".color-dett--finishes--carousel", {
      cellSelector: ".carousel-cell",
      prevNextButtons: false,
      groupCells: true
    });
    
    flktyColorFinishes.on( 'select', function() {
      $(".color-dett--finishes--carousel .carousel-cell.is-selected").removeClass('is-selected');
    }); 
  }

  if ($(".color-dett--finishes--descriptions").exists()) {

    var flktyColorFinishesDesc;
      flktyColorFinishesDesc = new Flickity(".color-dett--finishes--descriptions", {
      autoPlay: 0,
      cellSelector: ".item",
      prevNextButtons: false,
      pageDots: false,
      lazyLoad: 2,
      fade: true,
      draggable: false
    });

    $(".color-dett--finishes--carousel .carousel-cell").eq(0).addClass('active');
    $(".color-dett--finishes--carousel .carousel-cell").on('click', function(e){
      e.preventDefault();
      $(".color-dett--finishes--carousel .carousel-cell").removeClass('active');
      $(this).addClass('active');
      var i = $(this).index();
      flktyColorFinishesDesc.select(i, false, false);
    })
  }

  const fullView = {"ratio":1, "size":false};
  var viewer = null;
  $('.btn-zoom-closeup').on('click', function(e){
    e.preventDefault();
    var fullViewModalTitle = $('.fullview-title').text();
    var fullViewModalTitleFinishes = $(this).parent().find('.fullview-title_finishes').text();
    var image = $(this).data('image');    

    if(image === "" || image === null || image === undefined){
      return;
    }

    $("#spinner").addClass("default");
    spinner().show();
    
    var myModal =
      '<div class="modal" id="full-view">' +
        '<div class="modal-wrap full-view">' +
          '<div class="modal-nav section-padding__r-l">' +
            '<div class="title title-font xs">' +
              fullViewModalTitle + ' | ' + '<span class="full-view__finishes">' + fullViewModalTitleFinishes +'</span>' +
            '</div>' +
            '<a href="#close" rel="modal:close" class="close">' +
              '<svg viewBox="0 0 17.1 17.1" fill="currentColor"><polygon points="17.1,1.5 15.6,0 8.5,7.1 8.5,7.1 8.5,7.1 1.5,0 0,1.5 7.1,8.5 0,15.6 1.5,17.1 8.5,10 8.5,10 8.5,10 15.6,17.1 17.1,15.6 10,8.5 "/></svg>' +
            '</a>' +
          '</div>' +
          '<div class="modal-cont">' +
            '<div id="full-view--image"></div>' +
            '<div class="full-view--controls">' +
              '<div class="full-view--navigation__wrapper">' +
                '<div class="full-view--navigation" id="full-view--navigation"></div>' +
              '</div>' +
              '<div class="full-view--navigationControl" id="full-view--navigationControl">' +
                '<button id="full-view--zoomOut">' +
                  '<svg viewBox="0 0 16 2" fill="currentColor"><rect id="Rettangolo_9" width="16" height="2"/></svg>' +
                '</button>' +
                '<button id="full-view--zoomIn">' +
                  '<svg viewBox="0 0 16 16" fill="currentColor"><polygon points="16,7 9,7 9,0 7,0 7,7 0,7 0,9 7,9 7,16 9,16 9,9 16,9 "/></svg>' +
                '</button>' +
                '<button id="full-view--navigator__toggle">' +
                  '<svg fill="currentColor" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M2 13.5a.5.5 0 0 0 .5.5h6a.5.5 0 0 0 0-1H3.707L13.854 2.854a.5.5 0 0 0-.708-.708L3 12.293V7.5a.5.5 0 0 0-1 0v6z"/></svg>' +
                '</button>'+
              '</div>' +
            '</div>' +
          '</div>' +
        '</div>' +
      '</div>';

    var fullviewModal = $(myModal);

    fullviewModal.modal({
      blockerClass: "modal-fullscreen"
    });

    viewer = null;

    var isDesktop = window.matchMedia('(min-width:768px)').matches;
    var minZoomLevel = isDesktop ? 0.5 : 4;
    var maxZoomLevel = isDesktop ? 2 : 6;    

    fullviewModal.on($.modal.OPEN, function(event, modal) {
      if ((fullView.size || {}).unit) {
        document.querySelector('.full-view--navigation__wrapper').style.setProperty('--in-unit', "'" + fullView.size.unit.replace("'", "") + "'")
      }

      var descWidth = document.querySelector('#full-view .full-view--navigationControl').offsetWidth;

      viewer = new OpenSeadragon.Viewer({
        id: "full-view--image",
        constrainDuringPan: true,
        preserveImageSizeOnResize: true,
        defaultZoomLevel: isDesktop ? 1.8 : 4.5,
        // minZoomImageRatio: 0.75,
        minZoomLevel,
        maxZoomLevel,
        zoomPerClick: 1.4,
        zoomPerScroll: 1.2,
        // autoResize: false,
        visibilityRatio: 1,
        showNavigator: true,
        // showNavigationControl: false,
        navigatorHeight: descWidth / fullView.ratio,
        navigatorWidth: descWidth,
        navigatorId: "full-view--navigation",
        navigatorAutoFade:  false,
        showFullPageControl: false,
        showHomeControl: false,       
        showSequenceControl: false,
        homeButton: "",
        fullPageButton: "",
        nextButton: "", 
        previousButton: "",    
        toolbar: "",
        zoomInButton: "full-view--zoomIn",
        zoomOutButton: "full-view--zoomOut",
        useCanvas: false,
        // prefixUrl: "./",
        tileSources: {
          type: 'image',
          url: image,
          crossOriginPolicy: false,
          ajaxWithCredentials: false
        }
      });

      var cache = new OpenSeadragon.TileCache({maxImageCacheCount: 1});

      viewer.addHandler('animation', function(e){
        try {
          var size = e.eventSource.world._items[0].lastDrawn[0].size;
          var container = e.eventSource.viewport.containerSize;

          if(size.x < container.x){
            viewer.panHorizontal = false;
            viewer.viewport.centerSpringX.resetTo(.5)
          } else {
            viewer.panHorizontal = true;
          }

          if(size.y < container.y){
            viewer.panVertical = false;
            viewer.viewport.centerSpringY.resetTo(.5)
          } else {
            viewer.panVertical = true;
          }

          if(size.x < container.x && size.y < container.y){
            viewer.navigator.setMouseNavEnabled(false)
          } else{
            viewer.navigator.setMouseNavEnabled(true)
          }
        } catch (e) {
          console.error(e)
        } 
      });

      viewer.addHandler('tile-loaded', e=>{
        spinner().hide();
        setTimeout(function(){
          $("#spinner").removeClass("default");
        }, 500)
      });

      viewer.addHandler('zoom', function(e) {
          e.eventSource.zoomInButton.element.disabled = e.zoom >= e.eventSource.maxZoomLevel;
          e.eventSource.zoomOutButton.element.disabled = e.zoom <= e.eventSource.minZoomLevel
      })

      $('#full-view--navigator__toggle').on('click', function(){
        $(this).toggleClass('collapse');
        $('.full-view--navigation__wrapper').stop().slideToggle();
      })
    });

    fullviewModal.on($.modal.CLOSE, function(event, modal) {
      viewer.world.removeAll()
      viewer.world.resetItems()
      viewer.world.removeAllHandlers()
      viewer.imageLoader.clear();
      viewer.world.update()
      viewer._cancelPendingImages()
      viewer.removeReferenceStrip()
      viewer.clearControls()
      viewer.removeAllHandlers()
      viewer.removeControl()
      viewer.navigator = null;
      viewer.destroy();
      viewer = null;


      $('#full-view').remove();
    })
  })  

  if($('.video-autoplay').exists()){
    $('.video-autoplay').each(function(){
      var $this = $(this);
      var videoElement = $this.find(".video-autoplay__item")[0];
      var videoSrc = $this.find(".video-autoplay__item").data('src');

      videoElement.src = videoSrc;
      videoElement.load();

      videoElement.addEventListener('canplay', (e) => {

        let itemOnEnter = () => {
          $this.addClass('is-playing');
          videoElement.play();
        }
  
        let itemOnLeave = () => {
          $this.removeClass('is-playing');
          videoElement.pause();
        }
  
        var mediaTl = gsap.timeline({
          scrollTrigger: {
            trigger: $this[0],
            start: "top 90%",
            end: "bottom 15%",
            // markers: true,
            onEnter: itemOnEnter,
            onEnterBack: itemOnEnter,
            onLeave: itemOnLeave,
            onLeaveBack: itemOnLeave,
          }
        });
      });
    })
  }

  // scroll to div
  if ($(".scrollto").exists()) {
    $(".scrollto").click(function (e) {
      e.preventDefault();
      var link = $(this).data("link");
      $("html,body").animate(
        {
          scrollTop: $("#" + link).offset().top - $(".navbar").height(),
        },
        600
      );
    });
  }

  $('.img-zoom').not('.gallery__item').lightGallery({
    selector: 'this',
    download: false,
    counter: false,
    getCaptionFromTitleOrAlt: false
  });

  // open image popup
  $(".gallery__btn").lightGallery({
    selector: ".gallery__item",
    download: false,
    counter: false,
    getCaptionFromTitleOrAlt: false
  });

  // Open gallery from photogallery button
  if ($(".btn-gallery").exists()) {
    $(".btn-gallery").each(function () {
      var $this = $(this);
      var $lggallery = $("#" + $this.data("gallery"));

      $lggallery.lightGallery({
        selector: "img",
        download: false,
        counter: false,
        getCaptionFromTitleOrAlt: false
      });

      $this.click(function (e) {
        e.preventDefault();
        $lggallery.find("img").first().trigger("click");
      });
    });
  }

  // open video popup
  $(".btn-video").lightGallery({
    selector: "this",
    download: false,
    counter: false,
    videoMaxWidth: "80%",
    getCaptionFromTitleOrAlt: false,
    youtubePlayerParams: {
      modestbranding: 1,
      showinfo: 0,
      rel: 0,
      controls: 1,
    },
    vimeoPlayerParams: {
      byline: 0,
      portrait: 0,
    },
  });

  // Ajax modal
  $('a[rel="ajax:modal"]').unbind('click');
  $('a[rel="ajax:modal"]').click(function (event) {
    event.preventDefault();

    // console.log(this)

    this.blur();
    $.ajax({
      url: $(this).attr("href"),
      success: function (newHTML, textStatus, jqXHR) {
        var myHtml = $(newHTML).find(".ajax-load");
        $(myHtml).appendTo("body").modal();
      },
      error: function (jqXHR, textStatus, errorThrown) {
        // Handle AJAX errors
      },
      complete: function () {},
      // More AJAX customization goes here.
    });

    return false;
  });

  // Packery grid
  if ($(".grid").exists() && !$('.grid').hasClass('js-toa-grid')){
    packeryInstance = $(".grid").packery({
      // options
      itemSelector: ".grid-item",
      percentPosition: true,
    });
  }

  // Support - files modal + cookie
  if ($('a.files-list-item[data-modal]').exists()){
    $('a.files-list-item[data-modal]').click(function (event) {  
      var cookieSupportFiles = thomCookieGet('lapitec-support-files');
      if (cookieSupportFiles != 1 || cookieSupportFiles === null) {
        event.preventDefault();
        this.blur();

        var filesmodal = $($(this).data("modal"));

        filesmodal.modal();

        filesmodal.on($.modal.OPEN, function(event, modal) {
          var downloadForm = filesmodal.find('.thom-form');
          downloadForm.thomForm({
            errorMsg: downloadForm.find(".thom-form__error"),
            errorCallback: function(myTitle, myMessage) {
              if (myTitle) {
                myTitle = "<div class='paragraph'><strong>" + myTitle + "</strong></div>";
              }
              if (myMessage) {
                myMessage = "<div class='paragraph'>" + myMessage + "</div>";
              }

              if (myTitle || myMessage) {
                downloadForm.find(".thom-form__error").html(myTitle + myMessage);
              }
    
              downloadForm.find(".thom-form__error").fadeIn(300);
            },
            myCallback: function(myTitle, myMessage) {
              // MyThomFormResponseAction(myTitle, myMessage);
              if (myTitle !== undefined && myTitle !== null && myTitle !== "") {
                myTitle = "<div class='h2'>" + myTitle + "</div>";
              } else {
                myTitle = "";
              }

              var myHtml =
                '<div class="modal modal-download"">' +
                  '<a href="#close" rel="modal:close" class="close"><img src="/img/ico-close.svg"></a>' +
                  myTitle +
                  "<div class='paragraph'>" + myMessage + "</div>"; +
                '</div>';

              var filesresponsemodal = $(myHtml);

              $.modal.close();

              filesresponsemodal.modal();

              thomCookieSet('lapitec-support-files', '1', 365);

              filesresponsemodal.on($.modal.CLOSE, function(event, modal) {
                location.reload();
              })
            }
          });
        });

        return false;
      }  
    });
  }
  
  // form file
  if ($('.thom-form-selectfile').exists()) {
    $('.thom-form-selectfile').change(function (e) {
      e.preventDefault();
      var filename = $(this).find('.thom-form-selectfile__input_file').val().replace('C:\\fakepath\\', '')
      // var ext = filename.split('.').pop().toLowerCase()
      $(this).find('.thom-form-selectfile__label span').html(filename)
    })
  }

  if ($('.go-to-section').exists()){
    $('.go-to-section').on('click', function(){
      var link = $(this).data('link');
      $('html,body').animate({
        scrollTop: $(link).offset().top - $('.navbar').height()
      }, 600);
    })    
  }

  // update analytics
  if (typeof analyticsUpdate === "function") {
    analyticsUpdate();
  }
}

// start script
barba.use(barbaCss);

// define a global hook
barba.hooks.once((data) => {
  // this hook will be called for each transitions
  navbar();
  myapp();

  // Remove appended div to modal after close
  $(document).on($.modal.AFTER_CLOSE, function (event, modal) {
    $(".ajax-load.modal").remove();
  });

  // Analytics update
  $(document).on($.modal.OPEN, function (event, modal) {
    if (typeof analyticsUpdate === "function") {
      analyticsUpdate();
    }
    $(modal.$anchor).find(".close-modal").attr("data-barba-prevent", "self");
  });
  $(document).on($.modal.CLOSE, function (event, modal) {
    if (typeof analyticsUpdate === "function") {
      analyticsUpdate();
    }
  });

  $("#spinner").removeClass("default");
  // setTimeout(function() {
  //   $("#spinner");.remove()
  // }, 800);
});

barba.hooks.enter((data) => {
  $.ajax({
    url: data.next.url.href,
    success: function (newHTML, textStatus, jqXHR) {
      // Meta update
      var headTags = [
        "meta[name='keywords']",
        "meta[name='description']",
        "meta[property^='og']",
        "meta[name^='twitter']",
        "meta[itemprop]",
        "link[itemprop]",
        "link[rel='prev']",
        "link[rel='next']",
        "link[rel='canonical']",
      ].join(",");
      $("head").find(headTags).remove();
      $(newHTML).find(headTags).appendTo("head");

      // Section placeholder Change
      var newplaceholdersection = $(newHTML)
        .find(".placeholder-section")
        .html();
      $(".navbar .placeholder-section").html(newplaceholdersection);

      // Menu Change
      var newmenu = $(newHTML).find(".navbar__collapse").html();
      $(".navbar .navbar__collapse").html(newmenu);

      // Footer
      // var newfooter = $(newHTML).find('.footer-path').html();
      // $(".footer .footer-path").html(newfooter);

      // script variables
      var js = $(newHTML).find("script");
      if (js != null) {
        $.globalEval(js.html());
      }


      if($(newHTML).find(".js-toa").length > 0){
        toaDisable();
      }

      myapp();
    },
    error: function (xhr, ajaxOptions, thrownError) {
      alert(xhr.status)
      alert(xhr.statusText)
      // alert(thrownError)
    },
    complete: function () {
      spinner().hide();
    },
  });
});
barba.hooks.leave((data) => {
  // this hook will be called for each transitions
  // console.log("LEAVE - global hook");
  spinner().show();

  document.body.classList.remove("navbar--opened");

  // close an open modal
  $.modal.close();

  
});

barba.hooks.before((data) => {

  // $('.navbar').fadeOut()

});

// Basic default transition, with no rules and minimal hooks…
barba.init({
  timeout: 5000,
  cacheIgnore: true,
  prefetchIgnore: true,
  debug: false,
  views: [
    {
      namespace: "form",
      afterEnter(data) {
        if ($(".thom-form__recaptcha").length != 0) {
          recaptchaLoad();
        }

        if ($(".mtcaptcha").length != 0) {
          mt_jsloadedcb();
        }
      },
    },
    {
      namespace: 'homepage',
      beforeEnter({ next }) {
        if(ytVideoId != null || ytVideoId != undefined) {
          // prevent Google Map API script from being loaded multiple times
          if (typeof window.onYouTubeIframeAPIReady === 'function') {
            window.onYouTubeIframeAPIReady();
          } else {
            window.onYouTubeIframeAPIReady = () => {
              var player;
              player = new YT.Player('carouselHomeVideoPlayer', {
                videoId: ytVideoId, // YouTube Video ID
                width: 1280,            // Player width (in px)
                height: 720,            // Player height (in px)
                playerVars: {
                  playlist: '{$loadYouTubeVideoID}',
                  autoplay: 1,        // Auto-play the video on load
                  autohide: 1,
                  disablekb: 1,
                  controls: 0,        // Hide pause/play buttons in player
                  showinfo: 0,        // Hide the video title
                  modestbranding: 1,  // Hide the Youtube Logo
                  loop: 1,            // Run the video in a loop
                  fs: 0,              // Hide the full screen button
                  rel: 0,
                  enablejsapi: 1,
                  playsinline: 1
                },
                events: {
                  onReady: function (e) {
                    e.target.mute();
                    e.target.setPlaybackQuality('hd1080');
                    e.target.playVideo();
                    var emotionalContent = document.getElementsByClassName('emotional-home-content')[0];
                    emotionalContent.classList.add('tween');
                  },
                  onStateChange: function (e) {
                    if (e && e.data === 1) {
                      var videoHolder = document.getElementById('carouselHomeVideo');
                      if (videoHolder && videoHolder.id) {
                        videoHolder.classList.remove('loading');
                      }
                    } else if (e && e.data === 0) {
                      e.target.playVideo();
                    }
                  }
                }
              });
            };
    
            // load the Youtube Iframe API script
            let script = document.createElement('script');
            script.src = 'https://www.youtube.com/iframe_api';
            next.container.appendChild(script);
          }
        }
      }
    }
  ],
});
